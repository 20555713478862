<template>
  <ErrorPage :error :translation :is-main-page />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import type { NuxtError } from '#app'
import { useCurrentLocale, useT3Options } from '#imports'

const ErrorPage = defineAsyncComponent(
  () => import('~ui/components/Base/ErrorPage/ErrorPage.vue')
)

defineProps<{ error: NuxtError }>()

const currentLocale = useCurrentLocale()
const { options } = useT3Options()

const isMainPage = computed(() => !options.api.baseUrl?.includes('t3headless'))

const primaryTranslations = {
  en: {
    error: 'Error',
    header: "Unfortunately, the page you're looking for could not be found.",
    text: 'Check out our {{link}} to discover our {{link}}, {{link}} and {{link}}.',
    linkArray: [
      {
        label: 'home page',
        href: '/'
      },
      {
        label: 'services',
        href: '/services'
      },
      {
        label: 'clients',
        href: '/case-study'
      },
      {
        label: 'products',
        href: '/products'
      }
    ]
  },
  pl: {
    error: 'Błąd',
    header: 'Przepraszamy, nie udało nam się znaleźć szukanej strony.',
    text: 'Zapraszamy do odwiedzenia naszej {{link}}, gdzie znajdziesz informacje o naszych {{link}}, {{link}} i {{link}}.',
    linkArray: [
      {
        label: 'strony głównej',
        href: '/pl'
      },
      {
        label: 'usługach',
        href: '/pl/oferta'
      },
      {
        label: 'klientach',
        href: '/pl/case-study'
      },
      {
        label: 'produktach',
        href: '/pl/produkty'
      }
    ]
  },
  de: {
    error: 'Error',
    header: 'Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden.',
    text: 'Besuchen Sie unsere {{link}}, um mehr über unsere {{link}}, {{link}} und {{link}} zu erfahren.',
    linkArray: [
      {
        label: 'Homepage',
        href: '/de'
      },
      {
        label: 'Services',
        href: '/de/services'
      },
      {
        label: 'Kunden',
        href: '/de/case-study'
      },
      {
        label: 'Produkte',
        href: '/de/produkte'
      }
    ]
  }
}

const secondaryTranslations = {
  en: {
    error: 'Error',
    header: "Unfortunately, the page you're looking for could not be found.",
    text: 'Check out our {{link}}.',
    linkArray: [
      {
        label: 'home page',
        href: '/'
      }
    ]
  },
  de: {
    error: 'Error',
    header: 'Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden.',
    text: 'Besuchen Sie unsere {{link}}.',
    linkArray: [
      {
        label: 'Homepage',
        href: '/de'
      }
    ]
  }
}

type primaryTranslationsKey = keyof typeof primaryTranslations
type secondaryTranslationsKey = keyof typeof secondaryTranslations

const translation = isMainPage.value
  ? primaryTranslations[currentLocale.value as primaryTranslationsKey]
  : secondaryTranslations[currentLocale.value as secondaryTranslationsKey]
</script>
