import { defineNuxtPlugin } from '#app'
import { useRouter } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  const { SENTRY_DSN } = nuxtApp.$config.public
  if (!SENTRY_DSN) return

  const { vueApp } = nuxtApp
  const router = useRouter()

  const Sentry = async () => await import('@sentry/vue')

  Sentry().then((Sentry) => {
    Sentry.init({
      app: vueApp,
      dsn: SENTRY_DSN,
      environment: import.meta.env.MODE,
      sampleRate: 1.0,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.'
      ],
      logErrors: import.meta.env.DEV,
      integrations: [Sentry.browserTracingIntegration({ router })],
      tracesSampleRate: 1.0
    })

    vueApp.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['create', 'mount', 'update']
      })
    )

    Sentry.attachErrorHandler(vueApp, {
      logErrors: true,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['create', 'mount', 'update']
    })
  })
})
