import { defineNuxtPlugin } from '#app'

import { ClickOutside } from '~ui/helpers/plugins/click-outside'

declare global {
  interface HTMLElement {
    clickOutside?: (event: MouseEvent) => void
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', ClickOutside)
})
