import { createGtm, useGtm } from '@gtm-support/vue-gtm'

import { defineNuxtPlugin } from '#app'
import { useHead, useRouter } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  const { GTM_ID } = nuxtApp.$config.public
  if (!GTM_ID) return

  useHead({
    link: [
      {
        rel: 'preconnect',
        href: 'https://www.googletagmanager.com',
        key: 'preconnect-gtm'
      }
    ]
  })

  const router = useRouter()
  nuxtApp.vueApp.use(
    createGtm({
      id: GTM_ID,
      defer: false,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
      debug: import.meta.env.DEV
    })
  )

  nuxtApp.hook('gtm', (event) => {
    const gtm = useGtm()
    const dataLayer = gtm?.dataLayer()

    if (dataLayer) {
      dataLayer.push(event)
    }
  })
})
