import type { Ref } from 'vue'
import { ref } from 'vue'

import { useNuxtApp } from '#app'
import { useT3i18n } from '#imports'

const useCurrentLocale = <TranslationFile = never>() => {
  const { currentLocale: newLocale } = useT3i18n()
  const currentLocale = ref(newLocale.value)

  const { hook } = useNuxtApp()
  hook('page:loading:end', () => {
    currentLocale.value = newLocale.value
  })

  type ReturnType = [TranslationFile] extends [never]
    ? string
    : keyof TranslationFile
  return currentLocale as Ref<ReturnType>
}

export default useCurrentLocale
