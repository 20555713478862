import type { ObjectDirective } from 'vue'

export const ClickOutside: ObjectDirective = {
  mounted(el: HTMLElement, binding) {
    el.clickOutside = (event: MouseEvent) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event)
      }
    }
    document.body.addEventListener('click', el.clickOutside)
  },
  beforeUnmount(el: HTMLElement) {
    if (typeof el.clickOutside === 'function') {
      document.body.removeEventListener('click', el.clickOutside)
    }
  }
}
